import React, { useState } from 'react';
import axios from 'axios';
import logo from './mainlogo.png';
import lightbulb from './light.svg';
import book from './book.svg';
import coffee from './coffee.svg';

const Sidebar = ({ onCategoryChange, selectedCategory, isOpen, closeSidebar, isSmallScreen }) => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const handleCategoryClick = (category) => {
    onCategoryChange(category);
    closeSidebar(); 
  };
  
  const handleSubscribe = async () => {
    if (!email) {
      setMessage('Please enter a valid email address.');
      return;
    }

    const API_KEY = 'I5lPx-q8WCVkw3du0KILig';
    const FORM_ID = '7368839';

    try {
      console.log('Attempting to subscribe:', email);
      const response = await axios.post(
        `https://api.convertkit.com/v3/forms/${FORM_ID}/subscribe`,
        {
          api_key: API_KEY,
          email: email,
        }
      );
      
      console.log('API Response:', response.data);
      
      if (response.data.subscription) {
        setMessage('Thank you for subscribing!');
        setEmail('');
      } else {
        setMessage('Subscription failed. Please try again.');
      }
    } catch (error) {
      console.error('Error details:', error.response?.data || error);
      setMessage('An error occurred. Please try again later.');
    }
  };

  const handleFocus = (e) => {
    e.target.dataset.placeholder = e.target.placeholder;
    e.target.placeholder = '';
  };

  const handleBlur = (e) => {
    if (e.target.value === '') {
      e.target.placeholder = e.target.dataset.placeholder;
    }
  };

  const handleLogoClick = () => {
    onCategoryChange('Stories');
    if (isSmallScreen) {
      closeSidebar();
    }
  };

  return (
    <div className={`sidebar ${isOpen ? 'active' : ''}`}>
      {isSmallScreen && (
        <div className="header-open">
          <div className="logo-container">
            <img 
              src={logo} 
              className="ux-logo" 
              alt="UX Logo" 
              onClick={handleLogoClick}
              style={{ cursor: 'pointer' }}
            />
          </div>
          <div className="icon-container">
            <button className="toggle-sidebar-btn close-button" onClick={closeSidebar}>
              &times;
            </button>
          </div>
        </div>
      )}
      <div className="sidebar-top">
        <img 
          className="logo" 
          src={logo} 
          alt="UX Breakdowns Logo" 
          onClick={handleLogoClick}
          style={{ cursor: 'pointer' }}
        />
        <h2>Principles, ideas, and stories to help you build and grow better products.</h2>
        <nav>
          <ul>
            <li>
              <a
                href="#!"
                className={`svg-icon ${selectedCategory === 'Stories' ? 'active-text active-icon' : ''}`}
                onClick={() => handleCategoryClick('Stories')}
              >
                <img src={book} className={`svg-icon ${selectedCategory === 'Stories' ? 'active-icon' : ''}`} alt="Stories" />
                Stories
              </a>
            </li>
            <li>
              <a
                href="#!"
                className={`svg-icon ${selectedCategory === 'Ideas & Principles' ? 'active-text active-icon' : ''}`}
                onClick={() => handleCategoryClick('Ideas & Principles')}
              >
                <img src={lightbulb} className={`svg-icon ${selectedCategory === 'Ideas & Principles' ? 'active-icon' : ''}`} alt="Ideas & Principles" />
                Ideas & Principles
              </a>
            </li>
            <li>
              <a
                href="#!"
                className={`svg-icon ${selectedCategory === 'Roasts' ? 'active-text active-icon' : ''}`}
                onClick={() => handleCategoryClick('Roasts')}
              >
                <img src={coffee} className={`svg-icon ${selectedCategory === 'Roasts' ? 'active-icon' : ''}`} alt="Roasts" />
                Roasts
              </a>
            </li>
          </ul>
        </nav>
      </div>
      <div className="sidebar-bottom">
        <input
          type="email"
          placeholder="youremail@gmail.com"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          onFocus={handleFocus}
          onBlur={handleBlur}
        />
        <button className="subscribe-button" onClick={handleSubscribe}>
          Subscribe to our newsletter
        </button>
        <p>{message}</p>
        <button
          className="design-services-button"
          onClick={() => window.location.href = 'https://laylinedesign.com/'}
        >
          Need design services?
        </button>
      </div>
    </div>
  );
};

export default Sidebar;
